.choose-wrapper {
    width: 120px;
    height: 120px;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    margin-right: 3px;
    margin-bottom: 3px;
}

.choose-wrapper:hover {
    cursor: pointer;
}

@media screen and (min-width: 600px){
    .choose-wrapper {
        width: 150px;
        height: 150px;
    }
}