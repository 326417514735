.sidebar-nav::-webkit-scrollbar {
    width: 8px;
}

.sidebar-nav::-webkit-scrollbar-track {
    background-color: #313c54;
}

.sidebar-nav::-webkit-scrollbar-thumb {
    background-color: rgb(8, 13, 53);
    box-shadow: inset 0 0 6px rgb(84, 84, 84);
}