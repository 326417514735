@import "variables";

.dynamic-table-mobile {
    width: 100%;
    border: 1px solid $lightGrey;
    border-radius: 4px;
    font-size: 16px;

    .item {
        width: 100%;
        margin-bottom: 1px solid $lightGrey;
        margin-bottom: 12px;
        padding: 16px;

    }

    .tr {
        display: flex;
        margin-bottom: 8px;
    }



    .td:first-child {
        font-weight: bold;
    }

    .td:last-of-type {
        text-align: right;
        margin-left: auto;
    }

    .tr:last-of-type {
        .td {
            width: 100%;
        }
    }

}

.dynamic-table-desktop {
    width: 100%;
    border: 1px solid $lightGrey;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;

    thead tr {
        height: 32px;
    }

    thead tr th {
        font-weight: bold;
        font-size: 16px;
        padding-left: 12px;
    }

    tr td,
    tr th {
        border: 1px solid $lightGrey;
    }

    tbody tr td {
        height: 40px;
        padding-left: 12px;
        padding-right: 12px;
    }
}