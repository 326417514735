.shelf-wrapper {
    position: relative;
    padding: 1rem;
    display: inline-flex;
    background-color: #fff;
}

.shelf-talker-container {
    width: 100%;
    border: 1px solid #ccc;
    width: 200px;
    height: 275px;
    background-color: #fff;
}

.shelf-talker-container .shelf-talker-header {
    background-color: #fff;
    color: #000;
    height: 140px;
    padding: 0.3rem;
    overflow: hidden;
}

.shelf-talker-header .shelf-divider {
    width: 100%;
    border-bottom: 3px dashed #ccc;
    padding-top: 20px;
}

.shelf-talker-header .shelf-text-1 {
    width: 100%;
    border: 0 !important;
    background-color: #fff;
    color: #000;
    text-align: center;
    margin-top: 3px;
    font-size: 10px;
    text-transform: uppercase;
}

.shelf-talker-header .shelf-text-1.multi-line {
    padding: 0.1rem;
    font-size: 11px;
}


.shelf-talker-header .shelf-text-1::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #ccc;
}

.shelf-talker-header .shelf-text-1:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #ccc;
    opacity: 1;
}

.shelf-talker-header .shelf-text-1::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #ccc;
    opacity: 1;
}

.shelf-talker-header .shelf-text-1:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ccc;
}

.shelf-talker-header .shelf-text-1::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ccc;
}

.shelf-talker-header .shelf-text-1::placeholder {
    /* Most modern browsers support this now. */
    color: #ccc;
}

.shelf-talker-container .sht-body {
    padding: 0.3rem;
    border: 0 !important;
    background-color: white;
    border: none;
    outline: none;
}

.sht-body .text-inp {
    width: 100%;
    border: 0 !important;
    color: #000;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

.sht-body .text-inp-special {
    color: rgb(51, 51, 51);
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-size: 12px;
}


.sht-body .text-inp-special::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    letter-spacing: normal;
}

.sht-body .text-inp-special:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    letter-spacing: normal;
}

.sht-body .text-inp-special::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    letter-spacing: normal;
}

.sht-body .text-inp-special:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    letter-spacing: normal;
}

.sht-body .text-inp-special::-ms-input-placeholder {
    /* Microsoft Edge */
    letter-spacing: normal;
}

.sht-body .text-inp-special::placeholder {
    /* Most modern browsers support this now. */
    letter-spacing: normal;
}

.sht-body textarea {
    width: 100%;
    text-align: center;
    border: 0 !important;
    height: 65px;
    overflow: hidden;
    resize: none;
    line-height: 1.31em;
    font-size: 8px;
}

.shelf-floating-controls {
    position: absolute;
    right: -1rem;
    top: 5rem;
}

.shelf-floating-controls .shelf-control {
    background-color: #5141e0;
    padding: 0.3rem;
    border-radius: 3px;
    color: white;
    font-size: 8px;
    text-align: center;
    margin-bottom: 3px;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently*/

}

.shelf-floating-controls .shelf-control.text {
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.shelf-floating-controls .shelf-control.text span:first-of-type {
    font-size: 14px;
}

.shelf-control:hover {
    cursor: pointer;
}

.shelf-wrapper .shelf-color-picker {
    position: absolute;
    bottom: 0.6rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0.3rem;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.shelf-color-picker .color-wrapper {
    background-color: #fff;
    padding: 0.3rem;
    border-radius: 0.3rem;
    box-shadow: 3px 1px 3px #eee;
}

.shelf-wrapper .img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem;
    max-height: 200px;
}

.shelf-wrapper .img-container img {
    width: auto;
    height: 100%;
    max-width: 100%;
    max-height: 200px;
}

@media screen and (min-width: 400px) {
    .shelf-talker-container {
        width: 250px;
        height: 450px;
    }

    .sht-body textarea {
        height: 220px;
        font-size: 11px;
    }
}

@media screen and (min-width: 500px) {
    .shelf-talker-container .shelf-talker-header {
        height: 180px;
    }
    .shelf-talker-container {
        width: 300px;
        height: 500px;
    }

    .shelf-wrapper .img-container {
        max-height: 150px;
    }

    .shelf-wrapper .img-container img {
        max-height: 150px;
    }
    .sht-body textarea {
        height: 230px;
        font-size: 14px;
    }
}

@media screen and (min-width: 600px) {
    .shelf-talker-container .shelf-talker-header {
        height: 200px;
    }
    .shelf-talker-container {
        width: 350px;
        height: 550px;
    }

    .shelf-wrapper .img-container {
        max-height: 300px;
    }

    .shelf-wrapper .img-container img {
        max-height: 300px;
    }
    .sht-body textarea {
        height: 260px;
        font-size: 14px;
    }
}

@media screen and (min-width: 720px) {
    .shelf-talker-container .shelf-talker-header {
        height: 210px;
    }
    .shelf-talker-container {
        width: 360px;
        height: 560px;
    }

    .shelf-wrapper .img-container {
        max-height: 300px;
    }

    .shelf-wrapper .img-container img {
        max-height: 300px;
    }
}