.ex-works-table tr th, .ex-works-table tr td{
  text-align: center;
}

.ex-works-table tr th > small {
  font-size: 12px;
  color: #5f5a65;
  font-weight: bold;
}
.ex-works-table thead tr{
  background-color: #007bff;
}
.ex-works-table thead tr:hover{
  background-color: #003cff;
}

.ex-works-table thead tr th{
  color: whitesmoke;
  font-size: 17px;
  padding: 0.6rem;

}
.ex-works-table tr.average {
  background-color: #e3e1e1;
}
.ex-works-table tr.average td{
  color: #333;
}

.ex-works-table tr {
  border-color: #b7b7b7;
}
.ex-works-table td {
  border: 1px solid #b7b7b7;
}