.cc-steps {
    display: flex;
    width: 100%;;
    flex-direction: column;
    flex-wrap: wrap;
    
    .cc-step-wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
        
        .cc-step-content {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
            .cc-step-circle {
                width: 54px;
                height: 54px;
                min-width: 54px;
                min-height: 54px;
                border: 1px solid #2EB85C;
                background-color: #2EB85C;
                border-radius: 50%;
                display: flex;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .cc-step-circle.progress-0 {
                border-color: #D8DBE0;
                color: #D8DBE0;
                background-color: white;
            }
        }
       
    }
    .indicator-wrapper {
        display: flex;
        width: 100%;
        height: 64px;
        position: relative;
        margin-top: 8px;
        margin-bottom: 8px;
        .vertical, .horizontal{
            animation: all 1000ms ease-in-out;
        }
        .horizontal {
            display: none;;
        }
        .indicator-bg {
            height: 64px;
        }
        .indicator-bg, .indicator-active {
            width: 6px;
            position: absolute;
            left: 24px;
            background-color: #D8DBE0;
            border-radius: 6px;
        }
        .indicator-active {
            background-color: #2EB85C;
        }
    }
}

@media screen and (min-width: 768px){
    .cc-steps {
        display: flex;
        width: 100%;
        flex-direction: row;
        text-align: center;
        gap: 16px;

        .cc-step-wrapper {
            display: inline-flex;
            flex-direction: row;
            width: 200px;
        }
       .cc-step-content {
            display: flex;
            flex-direction: column;
            min-width: 160px;
            width: 160px;
            .cc-step-text {
                min-height: 100px;
            }
       }
       .indicator-wrapper {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            .vertical{
                display: none;
            }
            .horizontal {
                display: flex;
            }
            .indicator-bg.horizontal {
                height: 6px !important;
                width: 64px;
            }
            .indicator-active.horizontal {
                height: 6px;
                position: absolute            
            }
            .indicator-bg.horizontal, .indicator-active.horizontal {
                position: absolute;
                top: 18px;
            }
       }
      

    }
}