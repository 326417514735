.cc-avatar {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    background-color: #fff;
    background-position: center;
    background-size: cover;
    border: 1px solid #eee;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding: 0.3rem;
}

.cc-avatar.lg {
    width: 4rem;
    height: 4rem;
}