/* styles.css */
.styled-table {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Arial', sans-serif;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
}

.styled-table thead th {
  background-color: #343a40;
  color: #ffffff;
  text-transform: uppercase;
}

.styled-table tbody tr:nth-child(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:hover {
  background-color: #e9e9e9;
}

.section-header {
  background-color: #343a40;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
}

.section-subtotal {
  font-weight: bold;
}

.styled-table td input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  font-size: 14px;
}

.styled-table td input:focus {
  border-color: #343a40;
}

.my-3 button {
  background-color: #343a40;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.my-3 button:disabled {
  background-color: #a9a9a9;
  cursor: not-allowed;
}
