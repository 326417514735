.cpo-details {
    background: #F9F9F9;
    padding: 8px;
    border-radius: 9px;
}

table.orders-table {
    border-spacing: 0;
}

.order-row-selected {
    background-color: #D6D2F8 !important;
}