.tasting-menu-item {
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 3px;

}

.circle-fixed {
    width: 200px;
    height: 200px;
    border: 1px solid #eee;
    border-radius: 50%;
    display: flex;
    align-content: center;
    justify-content: center;
    text-align: center;
}

.circle-fixed p {
    margin: 0;
    padding: 0;
}

.circle {
    width: 15px;
    height: 15px;
    border: 1px solid #eee;
    border-radius: 50%;
    display: flex;
    align-content: center;
    justify-content: center;
    text-align: center;
}

.circle h1 {
    font-size: 5px;
    margin: 0;
    padding: 0;
}
.circle p {
    margin: 0.1rem;
    padding: 0;
    font-size: 1px;
}




.line-item {
    display: flex
}


.line-item p, .line-item .line{
    display: inline-flex;
    padding: 0;
    margin: 0;

}

.line-item p {
    margin-right: 0.3px;
    width: 60%;
    text-align: right;
    font-size: 2px;
}

.line {
    width: 30%;
    border-bottom: 1px solid #333;
    transform: translateY(-1px);
}

.line-ratings {
    display: flex;
    font-size: 2px;
    padding: 0;
    margin: 0;
}
.line-ratings p{
    padding: 0;
    margin: 0;
}
.ratings b{
    display: inline-flex;
    padding-left: 0.2px;
    padding-right: 0.2px;
}

.tasting-add-btn {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tasting-add-btn:hover {
    cursor: pointer;
}

.editInput {
    outline: 1px solid rgb(67, 196, 228);
    outline-width: 0 0 1px 0;
    border: none;
    width: 99%;
    text-align: center;
}

@media screen and (min-width: 18rem) {
    .tasting-menu-item {
        font-size: 4px;
        margin-bottom: 0.2rem;
        margin-top: 0.2rem;
    }
    .circle {
        border-width: 0.5px;
        width: 35px;
        height: 35px;
    }
    .circle h1 {
        font-size: 5px;
    }
    .circle p {
        font-size: 3px;
        margin: 0.5px;
        padding: 0;
    }
    .line-item p, .line-ratings p, .ratings b {
        font-size: 3px;
    }
    .ratings b {
        padding-left: 2px;
        padding-right: 2px;
    }
}

@media screen and (min-width: 29.5rem) {
    .tasting-menu-item {
        font-size: 10px;
        margin-bottom: 0.1rem;
        margin-top: 0.1rem;
    }
    .circle {
        width: 80px;
        height: 80px;
    }
    .circle h1 {
        font-size: 12px;
    }
    .circle p {
        font-size: 5px;
        margin: 0.5px;
        padding: 0;
    }
    .line-item p, .line-ratings p, .ratings b {
        font-size: 6px;
    }
    .ratings b {
        padding-left: 4px;
        padding-right: 4px;
    }
}

@media screen and (min-width: 48rem) {
    .tasting-menu-item {
        font-size: 12px;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
    }
    .circle {
        width: 110px;
        height: 110px;
    }
    .circle h1 {
        font-size: 18px;
    }
    .circle p {
        font-size: 9px;
        margin: 0.5px;
        padding: 0;
    }
    .line-item p, .line-ratings p, .ratings b {
        font-size: 9px;
    }
    .ratings b {
        padding-left: 5px;
        padding-right: 5px;
    }
}

@media screen and (min-width: 63rem) {
    .tasting-menu-item {
        font-size: 15px;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
    .circle {
        width: 130px;
        height: 130px;
    }
    .circle h1 {
        font-size: 22px;
    }
    .circle p {
        font-size: 10px;
        margin: 0.5px;
        padding: 0;
    }
    .line-item p, .line-ratings p, .ratings b {
        font-size: 10px;
    }
    .ratings b {
        padding-left: 6.5px;
        padding-right: 6.5px;
    }

}