// Here you can add other styles
.link {
    color: $blue;
    
    text-decoration: underline;

    &:hover {
        cursor: pointer;
    }
}

.table-container {
    overflow: hidden;
    overflow-x: auto;
    display: block;
    table {
        table-layout: fixed;
        td, th {
            word-break: normal;
        }
    }
}

.table-container.with-margin {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
}

.btn.btn-danger, .btn.btn-info {
    color: white;
}

.btn.btn-success {
    color: white;
}

.btn.btn-dark{
    background-color: black;
    color: #FFF;
}

.btn.btn-outline-dark:hover {
    background-color: #333333;
    color: #FFF;
}

.react-datepicker-wrapper input{
    width: 100%;
    border: 1px solid #ccc;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    font-size: 0.875rem;
    border-radius: 0.2rem;
}

.flex-center-horizontal {
    display: flex;
    align-items: center;
}
.no-select {
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
}
.flex-columns {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 1rem;
}
.flex-center-v {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
}
.status-flex {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
}
.hidden {
    display: none;
}