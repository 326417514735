.search-container-filterable {
    position: relative;
}


.search-container-filterable .search-results{
    position: absolute;
    margin: 0rem 0.75rem;
    background-color: white;
    box-shadow: 0 3px 6px #ccc;
    left: 0;
    right: 0;
    z-index: 800 !important
}
.search-container-filterable ul{
    margin: 0;
    padding: 0;
}

.search-container-filterable li {
    list-style: none;
    padding: 0.7rem;
}

.search-container-filterable li:hover {
    background: rgb(241, 240, 240);
    cursor: pointer;
}