.tasting-menu-wrapper {
    border: 1px solid #ccc;
    width: 5.85rem; /* A4 (in) x0.5*/
    height: 4.15rem; /* A4 (in) x0.5*/
    padding: 0.1rem;
}

.tasting-menu-wrapper .img-center {
    width: 18px;
}
.flex-center {
    display: flex;
    justify-content: center;
    align-content: center;
}
.agave-center-text {
    text-align: center;
}
.agave-center-text h1{
    font-weight: bold;
    font-size: 3px;
    text-align: center;
    margin: 0;
    padding: 0;
    font-family:  Rockwell,"Times New Roman",serif;
}
.agave-center-text h2{
    font-weight: 100;
    font-size: 3px;
    text-align: center;
    margin: 0;
    padding: 0;
}
.agave-center-text p{
    font-size: 1.5px;
    text-align: center;
}
@media screen and (min-width: 18rem) {
    .tasting-menu-wrapper {
        width: 11.7rem;
        height: 8.3rem;
    }
    .tasting-menu-wrapper .img-center {
        width: 40px;
    }
    .agave-center-text h1{
        font-size: 8px;
    }
    .agave-center-text h2{
        font-size: 8px;
    }
    .agave-center-text p{
        font-size: 5px;
    }
}

@media screen and (min-width: 29.5rem) {
    .tasting-menu-wrapper {
        width: 23.4rem;
        height: 16.6rem;
    }
    .tasting-menu-wrapper .img-center {
        width: 90px;
    }
    .agave-center-text h1{
        font-size: 15px;
    }
    .agave-center-text h2{
        font-size: 15px;
    }
    .agave-center-text p{
        font-size: 10px;
    }
}

@media screen and (min-width: 48rem) {
    .tasting-menu-wrapper {
        width: 35.1rem;
        height: 24.9rem;
    }
    .tasting-menu-wrapper .img-center {
        width: 150px;
    }
    .agave-center-text h1{
        font-size: 22px;
    }
    .agave-center-text h2{
        font-size: 22px;
    }
    .agave-center-text p{
        font-size: 12px;
    }
}

@media screen and (min-width: 63rem) {
    .tasting-menu-wrapper {
        width: 46.8rem;
        height: 33.2rem;
    }
    .tasting-menu-wrapper .img-center {
        width: 200px;
    }
    .agave-center-text h1{
        font-size: 25px;
    }
    .agave-center-text h2{
        font-size: 25px;
    }
    .agave-center-text p{
        font-size: 15px;
    }
}