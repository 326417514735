.folders {
    display: flex;
    flex-wrap: wrap;
}
.folders > div {
    display: flex;
    width: 120px; 
    height: 120px;
    margin: 6px;
    border: 1px solid #ccc;
    position: relative;
    align-items: center;
    justify-content: center;
}

.folders svg {
    width: 2rem !important;
    height: 2rem !important;
    display: block;
    margin: 0 auto;
}

.folders > div span {
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 0.2rem;
    min-width: 40px;
    text-align: center;
    border: 1px solid #B6D0E2	;
    border-radius: 5px;
    color: grey;
    background-color: white;
}
.folders > div div {
    color: grey;
}

.folders > div:hover {
    cursor: pointer;
    background-color: #ccc;
}

.selected-folder {
    display: block;
    border: 1px solid #ccc;
    margin-bottom: 9px;
    padding: 0.5rem;
}

.selected-folder:hover {
    cursor: pointer;
}

.art-items {
    display: flex;
    flex-wrap: wrap;   
}
.art-items img {
    width: 200px; 
    height: 200px;
    margin-right: 3px;
    margin-bottom: 6px;
}